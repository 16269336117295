import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";

export const GET_TAG_DETAIL_BY_ID_QUERY = gql`
	query tag($tagId: String!) {
		tagGet(id: $tagId) {
			isSuccess
			errorMessage
			code
			data {
				id
				name {
					...localeStringDetail
				}
				questionBankId
				subject
				difficulty
				createAt
			}
		}
	}

	${LOCALE_STRING_FIELDS}
`;
