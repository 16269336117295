import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql";

export const GET_QUESTIONS_BY_FILTER = gql`
	query questionsByFilter($questionFilterInput: QuestionFilterInput) {
		questionsGet(questionFilterInput: $questionFilterInput) {
			isSuccess
			code
			errorMessage
			data {
				id
				questionBankId
				topicAncestorIds
				level
				groupId
				isFlaged
				inputType
				content {
					inputType
					... on MCContent {
						body {
							...localeStringDetail
						}
					}
				}
			}
		}
	}

	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`;
