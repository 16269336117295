import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";

export const GET_CONCEPT_CARD_DETAIL_BY_ID_QUERY = gql`
	${LOCALE_STRING_FIELDS}
	query getConceptCardDetailById($conceptCardId: String!) {
		conceptCardGet(id: $conceptCardId) {
			isSuccess
			code
			errorMessage
			data {
				id
				name {
					...localeStringDetail
				}
				topicId
				topic {
					id
					name {
						...localeStringDetail
					}
				}
				questionBankId
				subject
				orderSequence
				tags {
					id
					name {
						...localeStringDetail
					}
				}
				createAt
			}
		}
	}
`;
