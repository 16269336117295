import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";

export const QB_QUESTION_BANK_CREATE_MUTATION = gql`
	mutation qbQuestionBankCreate($questionBankCreateInput: QuestionBankCreateInput!) {
		qbQuestionBankCreate(questionBankCreateInput: $questionBankCreateInput) {
			code
			isSuccess
			errorMessage
			data {
				id
				name{
					...localeStringDetail
				}
				examSystem
				subject
			}
		}
	}
	${LOCALE_STRING_FIELDS}
`