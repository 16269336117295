import { FIconTypes } from "@fantaskticedtechlimited/fui-iconlib";
import CheckLogin from "./Pages/Login/CheckLogin";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import LoginHome from "./Pages/Login/LoginHome";
import MagicLink from "./Pages/Login/MagicLink";
import Register from "./Pages/Login/Register";
import SentEmailMagicLink from "./Pages/Login/SentEmailMagicLink";
import VerifyEmailMagicLink from "./Pages/Login/VerifyEmailMagicLink";
import NotFound from "./Pages/NotFound";
import Setting from "./Pages/Setting";
import { FRouteConfig } from "./utils/useRouteService/types";
import Course from "./Pages/Course";

export const BeforeAuthRoutes: FRouteConfig[] = [
	{
		path: "/",
		component: Login,
		exact: true,
		menuName: "Login",
		breadcrumbName: "Login",
		hideInMenu: true,
		routes: [
			{
				component: CheckLogin,
				breadcrumbName: "Check Login",
				hideInMenu: true,
				index: true,
			},
			{
				path: "login",
				component: LoginHome,
				breadcrumbName: "LoginHome",
			},
			{
				path: "register",
				component: Register,
				breadcrumbName: "Register",
			},
			{
				path: "magicLink",
				component: MagicLink,
				breadcrumbName: "Magic Link",
			},
			{
				path: "sentMagicLink",
				component: SentEmailMagicLink,
				breadcrumbName: "Sent Magic Link",
			},
			{
				path: "verifyEmailMagicLink",
				component: VerifyEmailMagicLink,
				breadcrumbName: "Verify Magic Link",
			},
		],
	},
];
export const MainMenuRoutes: FRouteConfig[] = [
	{
		path: "/",
		component: Home,
		menuName: "Home",
		breadcrumbName: "Home",
		exact: true,
		iconName: FIconTypes.HOME,
	},
	{
		path: "/course",
		component: Course,
		menuName: "Course",
		breadcrumbName: "Course",
		exact: true,
		iconName: FIconTypes.HOME,
	},
	{
		// for redirect purpose
		path: "/login",
		component: NotFound,
		breadcrumbName: "Home",
		exact: true,
		hideInMenu: true,
		routes: [
			{
				path: "register",
				component: NotFound,
				breadcrumbName: "Register",
			},
			{
				path: "magicLink",
				component: NotFound,
				breadcrumbName: "Magic Link",
			},
			{
				path: "sentMagicLink",
				component: NotFound,
				breadcrumbName: "Sent Magic Link",
			},
			{
				path: "verifyEmailMagicLink",
				component: NotFound,
				breadcrumbName: "Verify Magic Link",
			},
		],
	},
	{
		path: "/notFound",
		component: NotFound,
		menuName: "NotFound",
		breadcrumbName: "NotFound",
		exact: true,
		hideInMenu: true,
	},
];

export const FooterMenuRoutes: FRouteConfig[] = [
	{
		path: "/settings",
		component: Setting,
		menuName: "Settings",
		breadcrumbName: "Settings",
		exact: true,
		iconName: FIconTypes.SETTING,
	},
];

export const AfterAuthRoutes = [...MainMenuRoutes, ...FooterMenuRoutes];
