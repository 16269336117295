import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql";

export const GET_QUESTIONBANK_CONTENT_QUERY = gql`

	query getQuestionBankContentById($id: String!) {
		questionBankGet(id: $id) {
			isSuccess
			errorMessage
			code
			data {
				id
				name {
					...localeStringDetail
				}
				subject
				examSystem
				folders {
					id
					name {
						...localeStringDetail
					}
					isEnable
					isFlagged
					icon {
						...photoDetail
					}
					topics {
						id
						name {
							...localeStringDetail
						}
					}
				}
				unclassifiedTopics {
					id
					name {
						...localeStringDetail
					}
					description
					isEnable
					isFlagged
					icon {
						...photoDetail
					}
				}
			}
		}
	}

	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}

`;
