import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql";

export const GET_TOPIC_DETAIL_BY_ID_QUERY = gql`
	query getTopicDetailById($topicId: String!) {
		topicGet(id: $topicId) {
			isSuccess
			code
			errorMessage
			data {
				id
				name {
					...localeStringDetail
				}
				questionBankId
				description
				isEnable
				isFlagged
				subject
				orderSequence
				icon {
					...photoDetail
				}
			}
		}
	}

	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`;
