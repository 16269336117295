import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql";

export const GET_QUESTIONS_DETAIL_BY_FILTER = gql`
	query questionsDetailByFilter($questionFilterInput: QuestionFilterInput) {
		questionsGet(questionFilterInput: $questionFilterInput) {
			isSuccess
			code
			errorMessage
			data {
				id
				questionBankId
				level
				groupId
				topicAncestorIds
				isFlaged
				inputType
				topics {
					id
					name {
						...localeStringDetail
					}
				}
				conceptCards {
					id
					name {
						...localeStringDetail
					}
				}
				content {
					inputType
					... on MCContent {
						body {
							...localeStringDetail
						}
						answers {
							id
							body {
								...localeStringDetail
							}
						}
						correctAnswerIds
						solution {
							...localeStringDetail
						}
					}
					... on ShortQuestionContent {
						body {
							...localeStringDetail
						}
						answer
						solution {
							...localeStringDetail
						}
					}
				}
				tags {
					id
					name {
						...localeStringDetail
					}
				}
				photoAttachments {
					...photoDetail
				}
			}
		}
	}

	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`;
