import { style } from 'typestyle'

export const pageButton = style({
    textAlign: "center",
    width: "185px",
    height: "48px",
    border: "2px solid #EE663C",
    borderRadius: "4px",
    color: "#EE663C",
    fontSize: "16px",
    lineHeight: "45px",
    boxSizing: "border-box",
    $nest: {
        '&:hover': {
            backgroundColor: "#EE663C",
            color: "white"
        }
    }
})

export const pageHeader = style({
    width: "100%",
    display: "flex",
    justifyContent:"space-between",
    fontFamily: "Montserrat",
})
