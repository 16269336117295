import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql";

export const QB_CONCEPT_CARD_DELETE_BY_ID_MUTATION = gql`
	mutation qbConceptCardDeleteById($conceptCardId: String!) {
		qbConceptCardDeleteById(conceptCardId: $conceptCardId) {
			isSuccess
			code
			errorMessage
			data {
				id
				name {
					en
					zh
				}
				topicId
				topic {
					id
					name {
						...localeStringDetail
					}
				}
				questionBankId
				subject
				orderSequence
				photo {
					...photoDetail
				}
				tags {
					id
					name {
						...localeStringDetail
					}
				}
				tagIds
				createAt
			}
		}
	}

	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`;
