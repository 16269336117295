import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql";

export const GET_FOLDERS_BY_FILTER = gql`
	query getFoldersByFilter($folderFilterInput: FolderFilterInput) {
		foldersGet(folderFilterInput: $folderFilterInput) {
			isSuccess
			errorMessage
			code
			data {
				id
				name {
					...localeStringDetail
				}
				isEnable
				isFlagged
				icon {
					...photoDetail
				}
				topics {
					id
					name {
						...localeStringDetail
					}
				}
			}
		}
	}
	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`;
