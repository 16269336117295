import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql";

export const GET_CONCEPT_CARDS_DETAIL_BY_FILTER = gql`
	query getConceptCardsDetailByFilter(
		$conceptCardFilterInput: ConceptCardFilterInput
	) {
		conceptCardsGet(conceptCardFilterInput: $conceptCardFilterInput) {
			isSuccess
			errorMessage
			code
			data {
				id
				name {
					...localeStringDetail
				}
				topicId
				questionBankId
				subject
				orderSequence
				photo {
					...photoDetail
				}
				tags {
					id
					name {
						...localeStringDetail
					}
				}
				createAt
			}
		}
	}

	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`;
