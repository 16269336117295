import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";

export const GET_CONCEPT_CARDS_BY_FILTER = gql`
	query getConceptCardsByFilter(
		$conceptCardFilterInput: ConceptCardFilterInput
	) {
		conceptCardsGet(conceptCardFilterInput: $conceptCardFilterInput) {
			isSuccess
			code
			errorMessage
			data {
				id
				name {
					...localeStringDetail
				}
				topicId
				questionBankId
				subject
				orderSequence
				createAt
			}
		}
	}

	${LOCALE_STRING_FIELDS}
`;
