import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql";

export const GET_TOPICS_BY_FILTER = gql`
	query getTopicsByFilter($topicFilterInput: TopicFilterInput) {
		topicsGet(topicFilterInput: $topicFilterInput) {
			isSuccess
			errorMessage
			code
			data {
				id
				name {
					...localeStringDetail
				}
				questionBankId
				description
				isEnable
				isFlagged
				subject
				orderSequence
				icon {
					...photoDetail
				}
			}
		}
	}
	
	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`;
