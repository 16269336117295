import { gql } from "@apollo/client"
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql"

export const QB_FOLDER_CREATE_MUTATION = gql`
	mutation qbFolderCreate($folderCreateInput: FolderCreateInput!) {
		qbFolderCreate(folderCreateInput: $folderCreateInput) {
			isSuccess
			code
			errorMessage
			data {
				id
				orderSequence
				name {
					...localeStringDetail
				}
			}
		}
	}
	${LOCALE_STRING_FIELDS}
`
