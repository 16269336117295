import * as globalStyles from "./assets/styles/global.styles";
import "./assets/styles/global.styles.css";
import ScreenSize from "./utils/screensize";
import { BrowserRouter as Router } from "react-router-dom";
import {
	MainMenuRoutes,
	FooterMenuRoutes,
	AfterAuthRoutes,
	BeforeAuthRoutes,
} from "./route";
import { PageRoutesRender } from "./layout/PageRoutesRender";
import { Fragment } from "react";
import { WithApolloGQLContext } from "./contexts/apolloGQLContext";
import { useAuthContext, WithAuthContext } from "./contexts/authContext";
import { WithUserSettingContext } from "./contexts/userSettingContext";
import RWDNavBar from "./layout/SideBar";
import { RWDMode } from "@fantaskticedtechlimited/fui-complib/lib/esm/global.types";
import WebFont from "webfontloader";
import { WithFirebaseMessagingContext } from "./contexts/firebaseMessagingContext";
import { WithFUIThemeContext } from "@fantaskticedtechlimited/fui-complib";
import { FColorTypes } from "@fantaskticedtechlimited/fui-colorlib";
import { FTheme } from "@fantaskticedtechlimited/fui-complib/lib/esm/FThemeContext/types";
import { WithPasswordProtectorWrapper } from "./layout/WithPasswordProtector";

WebFont.load({
	google: {
		families: ["Montserrat:500", "sans-serif", "Poppins"],
	},
});

function App() {
	const { isSignin } = useAuthContext();
	const [width] = ScreenSize();
	// const isSignin = true;
	const rwdMode =
		width < 720 ? RWDMode.MOBILE : width < 1280 ? RWDMode.TABLET : RWDMode.FULL;
	return (
		<Fragment>
			<Router>
				<div className={globalStyles.GlobalContainer(rwdMode !== RWDMode.FULL)}>
					{isSignin ? (
						<Fragment>
							{/* Left or Top RWD NavBar */}
							<RWDNavBar
								rwdMode={rwdMode}
								mainMenuRoutes={MainMenuRoutes}
								footerMenuRoutes={FooterMenuRoutes}
							/>
							<div className={globalStyles.PageContainer}>
								<div className={globalStyles.ContentContainer}>
									{/* <TopNav routes={AfterAuthRoutes} /> */}
									<PageRoutesRender
										routes={AfterAuthRoutes}
										redirectPathIfNotExist="/notFound"
									/>
								</div>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div className={globalStyles.FullPageContainer}>
								<PageRoutesRender routes={BeforeAuthRoutes} />
							</div>
						</Fragment>
					)}
				</div>
			</Router>
		</Fragment>
	);
}

const projectTheme: FTheme = {
	mainThemeColor: "#483FE2",
	subThemeColor: FColorTypes.DEFAULT_SUB_THEME,
};
export default WithPasswordProtectorWrapper(
	WithFirebaseMessagingContext(
		WithApolloGQLContext(
			WithAuthContext(
				WithUserSettingContext(WithFUIThemeContext(App, projectTheme))
			)
		)
	)
);
