import { gql } from "@apollo/client";

export const PHOTO_DETAIL_FIELDS = gql`
	fragment photoDetail on PhotoAttachment {
		attachmentFileType
		fileURL
		fileName
		filePath
		contentType
		originalFileName
		size
	}
`;
