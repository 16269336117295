import { gql } from "@apollo/client"
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql"

export const QB_TOPIC_CREATE_MUTATION = gql`
	mutation qbTopicCreate($topicCreateInput: TopicCreateInput!) {
		qbTopicCreate(topicCreateInput: $topicCreateInput) {
			isSuccess
			code
			data {
				id
				name {
					...localeStringDetail
				}
			}
		}
	}

	${LOCALE_STRING_FIELDS}
`
