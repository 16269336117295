import { FText } from "@fantaskticedtechlimited/fui-complib";
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib";
import { useAuthContext } from "../../../contexts/authContext";
import * as styles from "./style";

function HomeHeader() {
	const { currentUser } = useAuthContext();
	return (
		<div className={styles.pageHeader}>
			<FText font={FFontTypes.Small_Title()}>{`Welcome! ${
				currentUser?.email ?? "Guest"
			}`}</FText>
		</div>
	);
}

export default HomeHeader;
