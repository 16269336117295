import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";

export const GET_QUESTIONBANK_BY_FILTER = gql`
	query getQuestionBanksByFilter(
		$questionBankFilterInput: QuestionBankFilterInput
	) {
		questionBanksGet(questionBankFilterInput: $questionBankFilterInput) {
			isSuccess
			errorMessage
			code
			data {
				id
				name {
					...localeStringDetail
				}
				subject
				examSystem
			}
		}
	}

	${LOCALE_STRING_FIELDS}
`;
