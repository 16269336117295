import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";

export const QB_TAG_CREATE_MUTATION = gql`
	mutation qbTagCreate($tagCreateInput: TagCreateInput!) {
		qbTagCreate(tagCreateInput: $tagCreateInput) {
			code
			isSuccess
			errorMessage
			data {
				id
				name {
					...localeStringDetail
				}
			}
		}
	}
	${LOCALE_STRING_FIELDS}
`;
