import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";

export const QB_CONCEPT_CARD_CREATE_MUTATION = gql`
	mutation qbConceptCardCreate(
		$conceptCardCreateInput: ConceptCardCreateInput!
	) {
		qbConceptCardCreate(conceptCardCreateInput: $conceptCardCreateInput) {
			isSuccess
			code
			errorMessage
			data {
				id
				name {
					...localeStringDetail
				}
			}
		}
	}

	${LOCALE_STRING_FIELDS}
`;
