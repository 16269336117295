import { FButton, FText } from "@fantaskticedtechlimited/fui-complib";
import { FButtonTypes } from "@fantaskticedtechlimited/fui-complib/lib/esm/FButton/types";
import { ElementType, Fragment, useEffect, useState } from "react";
import { ImageAsset } from "../../assets/images";

export const WithPasswordProtectorWrapper = (Component: ElementType) => {
	return function WithPasswordProtectorWrapper(props: any) {
		const [isPass, setIsPass] = useState(false);
        const [errMessage, setErrMessage] = useState("")
		const [inputPassword, setInputPassword] = useState("");
		const passwordLocation = `${process.env.REACT_APP_PREFIX}_PASSWORD`;
		const validatePassword = async (_value: string, isFirstLoading ?:boolean) => {
			if (_value === process.env.REACT_APP_PASSWORD) {
				await localStorage.setItem(passwordLocation, _value);
				setIsPass(true);
			}else{
                !isFirstLoading && setErrMessage("Invalid Password")
            }
		};

		useEffect(() => {
			const init = () => {
				const oldPassword = localStorage.getItem(passwordLocation);
				validatePassword(oldPassword ?? "", true);
			};
			init();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		if (!isPass) {
			return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						rowGap: "12px",
						width: "100vw",
						height: "100vh",
					}}
				>
					<img
						src={ImageAsset.logo}
						alt="logo"
					/>
					<FText>Admin Portal - Beta Lock</FText>
					<input
						type="password"
						value={inputPassword}
						onChange={(e) => setInputPassword(e.currentTarget.value)}
						style={{
							width: "200px",
						}}
					/>
                    <FText
                        color="red"
                    >{errMessage}</FText>
					<FButton
						style={{
							width: "50px",
						}}
						onClick={() => validatePassword(inputPassword)}
						label={"Check"}
						type={FButtonTypes.PRIMARY}
					/>
				</div>
			);
		}

		return (
			<Fragment>
				<Component {...props} />
			</Fragment>
		);
	};
};
