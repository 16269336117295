import { gql } from "@apollo/client"
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql"
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql"

export const QB_TOPIC_UPDATE_BY_ID_MUTATION = gql`
	mutation qbTopicUpdateById($topicUpdateInput: TopicUpdateInput!) {
		qbTopicUpdateById(topicUpdateInput: $topicUpdateInput) {
			isSuccess
			code
			data {
				id
				name {
					...localeStringDetail
				}
				description
				orderSequence
				icon {
					...photoDetail
				}
				isEnable
				isFlagged
			}
		}
	}

	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`
