import { useMutation } from "@apollo/client";
import {
	TopicCreateInput,
	TopicUpdateInput,
	FolderAddOrRemoveTopicInput,
	TopicFilterInput,
	FolderCreateInput,
	FolderFilterInput,
	FolderUpdateInput,
	ConceptCardCreateInput,
	ConceptCardUpdateInput,
	ConceptCardFilterInput,
	TagFilterInput,
	TagCreateInput,
	QuestionCreateInput,
	QuestionFilterInput,
	QuestionUpdateInput,
	TopicOutput,
	FolderOutput,
	ConceptCardOutput,
	TagOutput,
	QuestionOutput,
	QuestionArrayOutput,
	TagArrayOutput,
	ConceptCardArrayOutput,
	FolderArrayOutput,
	TopicArrayOutput,
	QuestionBankArrayOutput,
	QuestionBankFilterInput,
	QuestionBankOutput,
	QuestionBankCreateInput,
} from "../../assets/type/backend.type";
import { useCustomLazyQuery } from "../../utils/useCustomLazyQuery";
import { QB_CONCEPT_CARD_CREATE_MUTATION } from "./mutation/qbConceptCardCreate.gql";
import { QB_CONCEPT_CARD_DELETE_BY_ID_MUTATION } from "./mutation/qbConceptCardDeleteById.gql";
import { QB_CONCEPT_CARD_UPDATE_BY_ID_MUTATION } from "./mutation/qbConceptCardUpdateById.gql";
import { QB_FOLDER_ADD_OR_REMOVE_TOPIC_BY_ID_MUTATION } from "./mutation/qbFolderAddOrRemoveTopicById.gql";
import { QB_FOLDER_CREATE_MUTATION } from "./mutation/qbFolderCreate.gql";
import { QB_FOLDER_DELETE_BY_ID_MUTATION } from "./mutation/qbFolderDeleteById.gql";
import { QB_FOLDER_UPDATE_BY_ID_MUTATION } from "./mutation/qbFolderUpdateById.gql";
import { QB_QUESTION_BANK_CREATE_MUTATION } from "./mutation/qbQuestionBankCreate.gql";
import { QB_QUESTION_CREATE_MUTATION } from "./mutation/qbQuestionCreate.gql";
import { QB_QUESTION_DELETE_BY_ID_MUTATION } from "./mutation/qbQuestionDeleteById.gql";
import { QB_QUESTION_UPDATE_BY_ID_MUTATION } from "./mutation/qbQuestionUpdate.gql";
import { QB_TAG_CREATE_MUTATION } from "./mutation/qbTagCreate.gql";
import { QB_TOPIC_CREATE_MUTATION } from "./mutation/qbTopicCreate.gql";
import { QB_TOPIC_DELETE_BY_ID_MUTATION } from "./mutation/qbTopicDeleteById.gql";
import { QB_TOPIC_UPDATE_BY_ID_MUTATION } from "./mutation/qbTopicUpdateById.gql";
import { GET_CONCEPT_CARD_DETAIL_BY_ID_QUERY } from "./query/getConceptCardDetailById.gql";
import { GET_CONCEPT_CARDS_BY_FILTER } from "./query/getConceptCardsByFilter.gql";
import { GET_CONCEPT_CARDS_DETAIL_BY_FILTER } from "./query/getConceptCardsDetailByFilter.gql";
import { GET_FOLDER_DETAIL_BY_ID_QUERY } from "./query/getFolderDetailById.gql";
import { GET_FOLDERS_BY_FILTER } from "./query/getFoldersByFilter.gql";
import { GET_QUESTIONBANK_CONTENT_QUERY } from "./query/getQuestionBankContentById.gql";
import { GET_QUESTIONBANK_CONTENT_DETAIL_QUERY } from "./query/getQuestionBankContentDetailById.gql";
import { GET_QUESTIONBANK_BY_FILTER } from "./query/getQuestionBanksByFilter.gql";
import { GET_QUESTIONBANK_DETAIL_BY_FILTER } from "./query/getQuestionBanksDetailByFilter.gql";
import { GET_QUESTION_DETAIL_BY_ID_QUERY } from "./query/getQuestionDetailById";
import { GET_QUESTIONS_BY_FILTER } from "./query/getQuestionsByFilter.gql";
import { GET_QUESTIONS_DETAIL_BY_FILTER } from "./query/getQuestionsDetailByFilter.gql";
import { GET_TAG_DETAIL_BY_ID_QUERY } from "./query/getTagDetailById";
import { GET_TAGS_BY_FILTER } from "./query/getTagsByFilter.gql";
import { GET_TOPIC_DETAIL_BY_ID_QUERY } from "./query/getTopicDetailById.gql";
import { GET_TOPICS_BY_FILTER } from "./query/getTopicsByFilter.gql";

// useQBGQLAPIService
const useQBAPIService = () => {
	// Get QuestionBank Content By Id
	const getQuestionBankContentById = useCustomLazyQuery<
		{ questionBankGet: QuestionBankOutput },
		{ id: string }
	>(GET_QUESTIONBANK_CONTENT_QUERY);

	// Get QuestionBank Content Detail By Id
	const getQuestionBankContentDetailById = useCustomLazyQuery<
		{ questionBankGet: QuestionBankOutput },
		{ id: string }
	>(GET_QUESTIONBANK_CONTENT_DETAIL_QUERY);

	// Get QuestionBanks By Filter
	const getQuestionBanksByFilter = useCustomLazyQuery<
		{ questionBanksGet: QuestionBankArrayOutput },
		{ questionBankFilterInput?: QuestionBankFilterInput }
	>(GET_QUESTIONBANK_BY_FILTER);

	// Get QuestionBanks Detail By Filter
	const getQuestionBanksDetailByFilter = useCustomLazyQuery<
		{ questionBanksGet: QuestionBankArrayOutput },
		{ questionBankFilterInput?: QuestionBankFilterInput }
	>(GET_QUESTIONBANK_DETAIL_BY_FILTER);

	// QuestionBank Create
	const [qbQuestionBankCreatMutation] = useMutation<
		{ qbQuestionBankCreate: QuestionBankOutput },
		{ questionBankCreateInput: QuestionBankCreateInput }
	>(QB_QUESTION_BANK_CREATE_MUTATION);


	// Get Topics By Filter
	const getTopicsByFilter = useCustomLazyQuery<
		{ topicsGet: TopicArrayOutput },
		{ topicFilterInput?: TopicFilterInput }
	>(GET_TOPICS_BY_FILTER);

	// Get Topic Detail By Id
	const getTopicDetailById = useCustomLazyQuery<
		{ topicGet: TopicOutput },
		{ topicId: string }
	>(GET_TOPIC_DETAIL_BY_ID_QUERY);

	// Topic Create
	const [qbTopicCreatMutation] = useMutation<
		{ qbTopicCreate: TopicOutput },
		{ topicCreateInput: TopicCreateInput }
	>(QB_TOPIC_CREATE_MUTATION);

	// Topic Update By Id
	const [qbTopicUpdateByIdMutation] = useMutation<
		{ qbTopicUpdateById: TopicOutput },
		{ topicUpdateInput: TopicUpdateInput }
	>(QB_TOPIC_UPDATE_BY_ID_MUTATION);

	// Topic Delete By Id
	const [qbTopicDeleteByIdMutation] = useMutation<
		{ qbTopicDeleteById: TopicOutput },
		{ topicId: string }
	>(QB_TOPIC_DELETE_BY_ID_MUTATION);

	// Folder Add or Remove Topic By Id
	const [qbFolderAddOrRemoveTopicByIdMutation] = useMutation<
		{ qbFolderAddOrRemoveTopicById: FolderOutput },
		{ folderAddOrRemoveTopicInput: FolderAddOrRemoveTopicInput }
	>(QB_FOLDER_ADD_OR_REMOVE_TOPIC_BY_ID_MUTATION);

	// Get Folders By Filter
	const getFoldersByFilter = useCustomLazyQuery<
		{ foldersGet: FolderArrayOutput },
		{ folderFilterInput?: FolderFilterInput }
	>(GET_FOLDERS_BY_FILTER);

	// Get Folder Detail By Id
	const getFolderDetailById = useCustomLazyQuery<
		{ folderGet: FolderOutput },
		{ folderId: string }
	>(GET_FOLDER_DETAIL_BY_ID_QUERY);

	// Folder Create
	const [qbFolderCreatMutation] = useMutation<
		{ qbFolderCreate: FolderOutput },
		{ folderCreateInput: FolderCreateInput }
	>(QB_FOLDER_CREATE_MUTATION);

	// Folder Update By Id
	const [qbFolderUpdateByIdMutation] = useMutation<
		{ qbFolderUpdateById: FolderOutput },
		{ folderUpdateInput: FolderUpdateInput }
	>(QB_FOLDER_UPDATE_BY_ID_MUTATION);

	// Folder Delete By Id
	const [qbFolderDeleteByIdMutation] = useMutation<
		{ qbFolderDeleteById: FolderOutput },
		{ folderId: string }
	>(QB_FOLDER_DELETE_BY_ID_MUTATION);

	// Get ConceptCards By Filter
	const getConceptCardsByFilter = useCustomLazyQuery<
		{ conceptCardsGet: ConceptCardArrayOutput },
		{ conceptCardFilterInput?: ConceptCardFilterInput }
	>(GET_CONCEPT_CARDS_BY_FILTER);

	// Get ConceptCards Detail By Filter
	const getConceptCardsDetailByFilter = useCustomLazyQuery<
		{ conceptCardsGet: ConceptCardArrayOutput },
		{ conceptCardFilterInput?: ConceptCardFilterInput }
	>(GET_CONCEPT_CARDS_DETAIL_BY_FILTER);

	// Get ConceptCard Detail By Id
	const getConceptCardDetailById = useCustomLazyQuery<
		{ conceptCardGet: ConceptCardOutput },
		{ conceptCardId: string }
	>(GET_CONCEPT_CARD_DETAIL_BY_ID_QUERY);

	// ConceptCard Create
	const [qbConceptCardCreatMutation] = useMutation<
		{ qbConceptCardCreate: ConceptCardOutput },
		{ conceptCardCreateInput: ConceptCardCreateInput }
	>(QB_CONCEPT_CARD_CREATE_MUTATION);

	// ConceptCard Update By Id
	const [qbConceptCardUpdateByIdMutation] = useMutation<
		{ qbConceptCardUpdateById: ConceptCardOutput },
		{ conceptCardUpdateInput: ConceptCardUpdateInput }
	>(QB_CONCEPT_CARD_UPDATE_BY_ID_MUTATION);

	// ConceptCard Delete By Id
	const [qbConceptCardDeleteByIdMutation] = useMutation<
		{ qbConceptCardDeleteById: ConceptCardOutput },
		{ conceptCardId: string }
	>(QB_CONCEPT_CARD_DELETE_BY_ID_MUTATION);

	// Get Tags By Filter
	const getTagsByFilter = useCustomLazyQuery<
		{ tagsGet: TagArrayOutput },
		{ tagFilterInput?: TagFilterInput }
	>(GET_TAGS_BY_FILTER);

	// Get Tag Detail By Id
	const getTagDetailById = useCustomLazyQuery<
		{ tagGet: TagOutput },
		{ tagId: string }
	>(GET_TAG_DETAIL_BY_ID_QUERY);

	// Tag Create
	const [qbTagCreatMutation] = useMutation<
		{ qbTagCreate: TagOutput },
		{ tagCreateInput: TagCreateInput }
	>(QB_TAG_CREATE_MUTATION);

	// // Tag Update By Id
	// const [qbTagUpdateByIdMutation] = useMutation<
	// 	{ qbTagUpdateById: QBTagUpdateOutput },
	// 	{ tagUpdateInput: TagUpdateInput }
	// >(QB_TAG_UPDATE_BY_ID_MUTATION);

	// // Tag Delete By Id
	// const [qbTagDeleteByIdMutation] = useMutation<
	// 	{ qbTagDeleteById: QBTagDeleteOutput },
	// 	{ tagId: string }
	// >(QB_TAG_DELETE_BY_ID_MUTATION);

	// Question CRUD

	// Get Questions By Filter
	const getQuestionsByFilter = useCustomLazyQuery<
		{ questionsGet: QuestionArrayOutput },
		{ questionFilterInput?: QuestionFilterInput }
	>(GET_QUESTIONS_BY_FILTER);

	// Get Questions Detail By Filter
	const getQuestionsDetailByFilter = useCustomLazyQuery<
		{ questionsGet: QuestionArrayOutput },
		{ questionFilterInput?: QuestionFilterInput }
	>(GET_QUESTIONS_DETAIL_BY_FILTER);

	// Get Question Detail By Id
	const getQuestionDetailById = useCustomLazyQuery<
		{ questionGet: QuestionOutput },
		{ questionId: string }
	>(GET_QUESTION_DETAIL_BY_ID_QUERY);

	// Question Create
	const [qbQuestionCreatMutation] = useMutation<
		{ qbQuestionCreate: QuestionOutput },
		{ questionCreateInput: QuestionCreateInput }
	>(QB_QUESTION_CREATE_MUTATION);

	// Question Update By Id
	const [qbQuestionUpdateByIdMutation] = useMutation<
		{ qbQuestionUpdateById: QuestionOutput },
		{ questionUpdateInput: QuestionUpdateInput }
	>(QB_QUESTION_UPDATE_BY_ID_MUTATION);

	// Question Delete By Id
	const [qbQuestionDeleteByIdMutation] = useMutation<
		{ qbQuestionDeleteById: QuestionOutput },
		{ questionId: string }
	>(QB_QUESTION_DELETE_BY_ID_MUTATION);

	return {
		// QuestionBank
		getQuestionBankContentById,
		getQuestionBankContentDetailById,
		getQuestionBanksByFilter,
		getQuestionBanksDetailByFilter,
		qbQuestionBankCreatMutation,

		// Topic
		getTopicsByFilter,
		getTopicDetailById,
		qbTopicCreatMutation,
		qbTopicUpdateByIdMutation,
		qbTopicDeleteByIdMutation,
		qbFolderAddOrRemoveTopicByIdMutation,

		// Folder
		getFoldersByFilter,
		getFolderDetailById,
		qbFolderCreatMutation,
		qbFolderUpdateByIdMutation,
		qbFolderDeleteByIdMutation,

		// Concept Card
		getConceptCardsByFilter,
		getConceptCardsDetailByFilter,
		getConceptCardDetailById,
		qbConceptCardCreatMutation,
		qbConceptCardUpdateByIdMutation,
		qbConceptCardDeleteByIdMutation,

		// Tag
		getTagsByFilter,
		getTagDetailById,
		qbTagCreatMutation,
		// qbTagUpdateByIdMutation,
		// qbTagDeleteByIdMutation,

		// Question
		getQuestionsByFilter,
		getQuestionsDetailByFilter,
		getQuestionDetailById,
		qbQuestionCreatMutation,
		qbQuestionUpdateByIdMutation,
		qbQuestionDeleteByIdMutation,
	};
};

export default useQBAPIService;
