import { gql } from "@apollo/client"

export const GET_MY_COURSES_QUERY = gql`
	query {
		myCourses {
			code
			isSuccess
			data {
				id
				userId
				userEmail
				courseName
				courseId
				role
				status
			}
		}
	}
`
