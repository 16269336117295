import { gql } from "@apollo/client"
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql"
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql"

export const QB_FOLDER_UPDATE_BY_ID_MUTATION = gql`
	mutation qbFolderUpdateById($folderUpdateInput: FolderUpdateInput!) {
		qbFolderUpdateById(folderUpdateInput: $folderUpdateInput) {
			isSuccess
			code
			errorMessage
			data {
				id
				orderSequence
				name {
					...localeStringDetail
				}
				subject
				icon {
					...photoDetail
				}
				isEnable
				isFlagged
			}
		}
	}

	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`
