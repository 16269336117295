import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";

export const GET_TAGS_BY_FILTER = gql`
	query tags($tagFilterInput: TagFilterInput) {
		tagsGet(tagFilterInput: $tagFilterInput) {
			isSuccess
			code
			errorMessage
			data {
				id
				name {
					...localeStringDetail
				}
				questionBankId
			}
		}
	}

	${LOCALE_STRING_FIELDS}
`;
