import { gql } from "@apollo/client"
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql"

export const QB_FOLDER_DELETE_BY_ID_MUTATION = gql`
	mutation qbFolderDeleteById($folderId: String!) {
		qbFolderDeleteById(folderId: $folderId) {
			isSuccess
			code
			data {
				id
				name {
					...localeStringDetail
				}
			}
		}
	}

	${LOCALE_STRING_FIELDS}
`
