import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";

export const QB_QUESTION_UPDATE_BY_ID_MUTATION = gql`
	mutation qbQuestionUpdateById($questionUpdateInput: QuestionUpdateInput!) {
		qbQuestionUpdateById(questionUpdateInput: $questionUpdateInput) {
			code
			isSuccess
			errorMessage
			data {
				id
				questionBankId
				level
				groupId
				topicAncestorIds
				isFlaged
				inputType
				content {
					... on MCContent {
						body {
							...localeStringDetail
						}
						answers {
							id
							body {
								...localeStringDetail
							}
						}
						correctAnswerIds
						solution {
							...localeStringDetail
						}
					}
				}
				tags {
					id
					name {
						...localeStringDetail
					}
				}
				conceptCards {
					id
					name {
						...localeStringDetail
					}
				}
				photoAttachments {
					...photoDetail
				}
			}
		}
	}

	${LOCALE_STRING_FIELDS}
`;
