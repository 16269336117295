import * as style from "./style";
import PageContainer from "../../layout/PageContainer";
import { FIcon, FIconTypes } from "@fantaskticedtechlimited/fui-iconlib";
import one from "../../assets/images/one.svg";
import two from "../../assets/images/two.svg";
import three from "../../assets/images/three.svg";
import four from "../../assets/images/four.svg";
import five from "../../assets/images/five.svg";
import { FScrollable, FText } from "@fantaskticedtechlimited/fui-complib";
import HomeHeader from "./HomeHeader";
import { FFontTypes } from "@fantaskticedtechlimited/fui-fontlib";

const Home = () => {
	const cardData = [
		{
			number: 12,
			name: "Member Joining Request",
			description: "Go to Member",
			icon: FIconTypes.USER,
		},
		{
			number: 5,
			name: "Chat Message",
			description: "Go to Chat",
			icon: FIconTypes.EMAIL,
		},
		{
			number: 5,
			name: "Event Participation Request",
			description: "Go to Event",
			icon: FIconTypes.CALENDAR,
		},
	];

	const actionsData = [
		{
			title: "Create an Event",
			description: "Engage your members with awesome events",
			image: one,
		},
		{
			title: "Broadcast a Message",
			description: "Send a message to your members or event participiants",
			image: two,
		},
		{
			title: "Start a Polling",
			description: "Start a polling among your members or event participants",
			image: three,
		},
		{
			title: "Build a Form",
			description:
				"Build a from to request information from your memebers or participants",
			image: four,
		},
		{
			title: "Create a Highlight",
			description:
				"Create highlight so non-members can learn more about your society",
			image: five,
		},
	];

	return (
		<PageContainer
			header={<HomeHeader />}
			content={
				<FScrollable>
					<div className={style.HomeContainer}>
						<FText font={FFontTypes.Title()}>Overview</FText>
						<div className={style.overviewsContainer}>
							{cardData.map((post, i) => (
								<div className={style.overviewCard} key={i}>
									<div className={style.overview_item_header}>
										<label
											style={{
												fontSize: "32px",
												color: "#EE663C",
												fontWeight: 500,
												fontFamily: "Poppins",
											}}
										>
											{post.number}
										</label>
										<br />
										<label
											style={{
												fontSize: "14px",
												fontFamily: "Poppins",
											}}
										>
											{post.name}
										</label>
									</div>
									<div className={style.navigation_button}>
										<FIcon
											name={post.icon}
											size="small"
											strokeColor="#EE663C"
										/>
										<label
											style={{
												fontSize: "10px",
												color: "#EE663C",
												fontFamily: "Poppins",
											}}
										>
											{post.description}
										</label>
									</div>
								</div>
							))}
						</div>
						<div className={style.divider}></div>
						<FText font={FFontTypes.Title()}>Quick Actions</FText>

						<div className={style.quickActionsContainer}>
							{actionsData.map((post, i) => (
								<div className={style.quickActionEventCard} key={i}>
									<div
										className={style.actionImage}
										style={{
											backgroundImage: `url(${post.image})`,
										}}
									></div>
									<div className={style.actionText}>
										<label
											style={{
												fontSize: "16px",
												fontWeight: "500",
												fontFamily: "Poppins",
											}}
										>
											{post.title}
										</label>
										<br />
										<label
											style={{
												fontSize: "12px",
												fontFamily: "Poppins",
												lineHeight: "0.3px",
											}}
											className={style.descriptionLabel}
										>
											{post.description}
										</label>
									</div>
								</div>
							))}
						</div>
					</div>
				</FScrollable>
			}
		/>
	);
};

export default Home;
