import {
    CourseMemberArrayOutput,
} from "../../assets/type/backend.type";
import { useCustomLazyQuery } from "../../utils/useCustomLazyQuery";
import { GET_MY_COURSES_QUERY } from "./query/getMyCourses.gql";


const useCourseAPIService = () => {

	const getMyCourses = useCustomLazyQuery<{ myCourses: CourseMemberArrayOutput }>(GET_MY_COURSES_QUERY);
	return {
        getMyCourses
	};
};

export default useCourseAPIService;
