import { FSelector } from "@fantaskticedtechlimited/fui-complib";
import { useCallback } from "react";
import useCourseAPIService from "../../apiService/course";
import useQBAPIService from "../../apiService/questionBank";
import {  CourseMember } from "../../assets/type/backend.type";
import { useUserSettingContext } from "../../contexts/userSettingContext";
import useMount from "../../utils/useMount";
import { useStateSafe } from "../../utils/useStateSafe";
import { CourseSelectDropDownProps } from "./type";

function CourseMemberSelectDropDown(props: CourseSelectDropDownProps) {
	const QBAPI = useQBAPIService();
	const CourseAPI = useCourseAPIService()
	const { currentUserSetting, setCourseMember } = useUserSettingContext();
	const [courseMemberList, setCourseMemberList] = useStateSafe<
		CourseMember[]
	>([]);

	const handleGetMyCourses = useCallback(async () => {
		try {
			const MyCoursesResult = await CourseAPI.getMyCourses({});
			if (MyCoursesResult.data.myCourses.isSuccess) {
				setCourseMemberList(MyCoursesResult.data.myCourses.data || []);
			}
		} catch (error) {}
	}, [QBAPI, setCourseMemberList]);

	const handleSelectCourseMember = (value: CourseMember | null) =>
	setCourseMember(value);

	useMount(() => {
		handleGetMyCourses();
	});

	return (
		<FSelector
			label={`Course`}
			showLabelOnly
			options={courseMemberList || []}
			onSelect={handleSelectCourseMember}
			selectedOptions={currentUserSetting.courseMember}
			renderOptionNameOnly={(data) => data.courseName}
			onClear={() => handleSelectCourseMember(null)}
		/>
	);
}

export default CourseMemberSelectDropDown;
