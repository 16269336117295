import { gql } from "@apollo/client"
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql"
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql"

export const GET_FOLDER_DETAIL_BY_ID_QUERY = gql`
	query getFoldersByFilter($folderId: String!) {
		folderGet(id: $folderId) {
			isSuccess
			code
			errorMessage
			data {
				id
				name {
					...localeStringDetail
				}
				questionBankId
				isEnable
				isFlagged
				icon {
					...photoDetail
				}
				topics {
					id
					name {
						...localeStringDetail
					}
					questionBankId
					description
					isEnable
					isFlagged
					subject
					orderSequence
					icon {
						...photoDetail
					}
				}
			}
		}
	}
	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`
