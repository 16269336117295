import { gql } from "@apollo/client";
import { LOCALE_STRING_FIELDS } from "../../common/fragments/localeStringDetail.gql";
import { PHOTO_DETAIL_FIELDS } from "../../common/fragments/photoDetail.gql";

export const QB_QUESTION_CREATE_MUTATION = gql`
	mutation qbQuestionCreate($questionCreateInput: QuestionCreateInput!) {
		qbQuestionCreate(questionCreateInput: $questionCreateInput) {
			code
			isSuccess
			errorMessage
			data {
				id
				level
				groupId
				topicAncestorIds
				isFlaged
				inputType
				content {
					... on MCContent {
						body {
							...localeStringDetail
						}
						answers {
							id
							body {
								...localeStringDetail
							}
						}
						correctAnswerIds
						solution {
							...localeStringDetail
						}
					}
				}
				tags {
					id
					name {
						...localeStringDetail
					}
				}
				conceptCards {
					id
					name {
						...localeStringDetail
					}
				}
				photoAttachments {
					...photoDetail
				}
			}
		}
	}
	${LOCALE_STRING_FIELDS}
	${PHOTO_DETAIL_FIELDS}
`