import { FColorTypes } from '@fantaskticedtechlimited/fui-colorlib'
import {style} from 'typestyle'


export const HomeContainer = style({
    width: "100%",
    height:"100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    boxSizing:"border-box",
    padding:"12px"
})

export const overviewsContainer = style({
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
    alignItems:"center",
    rowGap: "12px",
    columnGap: "12px",
    width: "100%",
    flexWrap:"wrap"
})

export const overviewCard = style({
    flexGrow:1,
    width: "200px",
    background: "#F3F6FA",
    border: "1px solid rgba(0, 0, 0, 0.02)",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px"
})

export const overview_item_header = style({
    height: "104px",
    boxSizing: "border-box",
    padding: "12px",
})

export const navigation_button = style({
    width: "100%",
    height: "40px",
    boxSizing: "border-box",
    padding: "12px",
    borderTop: "1px solid #E2E2E2",
    display: "flex",
    columnGap: "8px"
})

export const divider = style({
    height: "1px",
    width: "100%",
    backgroundColor: "#E2E2E2",
})

export const quickActionsContainer = style({
    width: "100%",
    display:"flex",
    flexDirection: "row",
    justifyContent:"space-between",
    alignItems:"center",
    flexWrap: "wrap",
    rowGap: "12px",
    columnGap: "12px"
})

export const quickActionEventCard = style({
    flexGrow:1,
    width: "300px",
    background: "#F3F6FA",
    border: "1px solid rgba(0, 0, 0, 0.02)",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    boxSizing: "border-box",
    padding: "16px",
    display: "flex",
    columnGap: "12px",
    $nest: {
        '&:hover':{
            background: FColorTypes.UNIPLUS_ORANGE,
            color: FColorTypes.PRIMARY_WHITE + "!important"
        }
    }
})

export const descriptionLabel = style({
    color: "#B0B6BB"
})

export const actionImage = style({
    width: "48px",
    maxHeight: "48px",
})

export const actionText = style({
})